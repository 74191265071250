import { useState, useEffect } from 'react'
import './App.css'
import { getAllCoffees } from './services/coffees/getAllCoffees'
import { createCoffee } from './services/coffees/createCoffee'
import { deleteCoffee } from './services/coffees/deleteCoffee'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCoffee, faCircleNotch, faRocket } from '@fortawesome/free-solid-svg-icons'
import { createAvatar } from '@dicebear/avatars'
import * as style from '@dicebear/big-ears-neutral'
import CoffeAppLogo from './assets/coffee-app.svg'
import { WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, LineShareButton, LineIcon } from 'react-share'

function App () {
  let urlElements = window.location.href.split('/')

  if (localStorage.getItem('latestCoffeeSpace') && !urlElements[3]) {
    window.history.replaceState(null, '', localStorage.getItem('latestCoffeeSpace'))
  }

  urlElements = window.location.href.split('/')

  const urlCoffeeSpaceStart = urlElements[3] || ''
  const [coffees, setCoffees] = useState([])
  const [newPersonName, setNewPersonName] = useState('')
  const [coffeeSpaceState, setCoffeeSpaceState] = useState(urlCoffeeSpaceStart)
  const [newAvatar, setNewAvatar] = useState('')
  const [newCoffeeType, setNewCoffeeType] = useState('cafe_con_leche')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [faltanDatos, setFaltanDatos] = useState(false)
  const [faltaCoffeeSpace, setFaltaCoffeeSpace] = useState(false)
  const [success, setSuccess] = useState(false)

  const countConLeche = coffees.filter((obj:any) => obj.coffeeType === 'cafe_con_leche').length
  const countConLecheFria = coffees.filter((obj:any) => obj.coffeeType === 'cafe_con_leche_fria').length
  const countSolos = coffees.filter((obj:any) => obj.coffeeType === 'cafe_solo').length
  const countSolosConHielo = coffees.filter((obj:any) => obj.coffeeType === 'cafe_solo_con_hielo').length
  const countTeas = coffees.filter((obj:any) => obj.coffeeType === 'tea').length
  const countTeasSolos = coffees.filter((obj:any) => obj.coffeeType === 'tea_solo').length
  const countCocacola = coffees.filter((obj:any) => obj.coffeeType === 'cocacola').length
  const countMentaPoleo = coffees.filter((obj:any) => obj.coffeeType === 'menta_poleo').length
  const countCafeCortado = coffees.filter((obj:any) => obj.coffeeType === 'cafe_cortado').length
  const coffeTypes = ['cafe_con_leche', 'cafe_con_leche_fria', 'cafe_solo', 'cafe_solo_con_hielo', 'cafe_cortado', 'tea_solo', 'tea', 'cocacola', 'menta_poleo']

  const renderCoffeType = (coffeeType: any) => {
    switch (coffeeType) {
      case 'cafe_solo':
        return {
          name: 'Café solo',
          emoji: '☕'
        }
      case 'cafe_solo_con_hielo':
        return {
          name: 'Café solo con hielo',
          emoji: '☕🧊'
        }
      case 'cafe_con_leche':
        return {
          name: 'Café con leche',
          emoji: '☕'
        }
      case 'cafe_con_leche_fria':
        return {
          name: 'Café con leche fría',
          emoji: '☕🥶'
        }
      case 'tea_solo':
        return {
          name: 'Té verde',
          emoji: '🍵'
        }
      case 'tea':
        return {
          name: 'Té verde con limón',
          emoji: '🍵🍋'
        }
      case 'cocacola':
        return {
          name: 'Cocacola',
          emoji: '🥤'
        }
      case 'menta_poleo':
        return {
          name: 'Menta Poleo',
          emoji: '🌿🧉'
        }
      case 'cafe_cortado':
        return {
          name: 'Café cortado',
          emoji: '☕✂️'
        }
      default:
        return {
          name: 'Desconocido',
          emoji: '😕'
        }
    }
  }

  useEffect(() => {
    if (coffeeSpaceState) {
      setLoading(true)
      getAllCoffees({ coffeeSpace: coffeeSpaceState }).then((posts) => {
        setCoffees(posts)
        setLoading(false)
      })
    } else {
      setFaltaCoffeeSpace(true)
    }
  }, [])

  let textAreaText = 'Buenos días!\n'
  countConLeche > 0 && (textAreaText += `${countConLeche} ${renderCoffeType('cafe_con_leche').name}\n`)
  countConLecheFria > 0 && (textAreaText += `${countConLecheFria} ${renderCoffeType('cafe_con_leche_fria').name}\n`)
  countSolos > 0 && (textAreaText += `${countSolos} ${renderCoffeType('cafe_solo').name}\n`)
  countSolosConHielo > 0 && (textAreaText += `${countSolosConHielo} ${renderCoffeType('cafe_solo_con_hielo').name}\n`)
  countCafeCortado > 0 && (textAreaText += `${countCafeCortado} ${renderCoffeType('cafe_cortado').name}\n`)
  countTeas > 0 && (textAreaText += `${countTeas} ${renderCoffeType('tea').name}\n`)
  countTeasSolos > 0 && (textAreaText += `${countTeasSolos} ${renderCoffeType('tea_solo').name}\n`)
  countCocacola > 0 && (textAreaText += `${countCocacola} ${renderCoffeType('cocacola').name}\n`)
  countMentaPoleo > 0 && (textAreaText += `${countMentaPoleo} ${renderCoffeType('menta_poleo').name}\n`)

  useEffect(() => {
    const interval = setInterval(() => {
      const urlElements = window.location.href.split('/')
      const urlCoffeeSpace = urlElements[3] || ''
      if (urlCoffeeSpace) {
        getAllCoffees({ coffeeSpace: urlCoffeeSpace }).then((posts) => {
          setCoffees(posts)
          setLoading(false)
        })
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const handleChangePersonName = (event: any) => {
    setNewPersonName(event.target.value)
    setNewAvatar(event.target.value)
  }

  const handleChangeCoffeeType = (event: any) => {
    setNewCoffeeType(event.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()

    if (newPersonName === '' || newCoffeeType === '') {
      setFaltanDatos(true)
      setSuccess(false)
    } else {
      const coffeeAddToState = {
        personName: newPersonName,
        coffeeType: newCoffeeType,
        coffeeSpace: coffeeSpaceState
      }
      setFaltanDatos(false)
      setError(false)

      createCoffee(coffeeAddToState)
        .then((newPost) => {
          setCoffees((prevPosts) => prevPosts.concat(newPost))
          setNewPersonName('')
          setNewCoffeeType('cafe_con_leche')
          setSuccess(true)
          setNewAvatar('')
        })
        .catch((error) => {
          console.error(error)
          setError(true)
        })
    }
  }

  const handleDelete = (coffeeId: any) => {
    const id = coffeeId
    deleteCoffee({ id }).then(() => {
      getAllCoffees({ coffeeSpace: coffeeSpaceState }).then((posts) => {
        setCoffees(posts)
        setLoading(false)
      })
    })
  }

  const handleFocus = (event: any) => event.target.select()

  const handleChangeCoffeeSpace = (event: any) => {
    setCoffeeSpaceState(event.target.value)
  }

  const handleSubmitChangeCoffeeSpace = (event: any) => {
    event.preventDefault()

    if (coffeeSpaceState === '') {
      setFaltaCoffeeSpace(true)
      setSuccess(false)
    } else {
      window.history.replaceState(null, '', coffeeSpaceState)
      setLoading(true)
      setCoffees([])
      getAllCoffees({ coffeeSpace: coffeeSpaceState }).then((posts) => {
        setCoffees(posts)
        setLoading(false)
        setSuccess(false)
      })
      setFaltaCoffeeSpace(false)
      setError(false)
      localStorage.setItem('latestCoffeeSpace', coffeeSpaceState)
    }
  }

  const renderUserAvatar = (seed: any, size: number) => {
    return createAvatar(style, {
      dataUri: true,
      seed: seed,
      size: size,
      radius: 10
    })
  }

  return (
    <div className="App">

      <div className="content center">
        <div className="box-content">

          <div>
            <h3>¡Hola! ¿A que CoffeeSpace perteneces?</h3>
            <input type="text" style={{ width: '62%', display: 'inline-block' }} onChange={handleChangeCoffeeSpace} value={coffeeSpaceState} maxLength={20} placeholder="Equipo A, Los Vengadores..."/>
            <a onClick={handleSubmitChangeCoffeeSpace} className="btn-change-coffeespace"><FontAwesomeIcon icon={faRocket} />  Viajar </a>
          </div>

          <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
            <div className="coffe-app-data">
              <h1 className="coffee-app-title">Coffee<span className="coffee-app-title-exclamation">!</span></h1>
              <img className="coffee-app-logo" src={CoffeAppLogo} alt="React Logo" />
            </div>

            <div className="coffe-form-data">
              <form onSubmit={handleSubmit}>

                <div>
                  <h3>¿Cómo te llamas hoy?</h3>
                  {newAvatar ? (<img style={{ paddingRight: '15px', verticalAlign: 'middle' }} src={renderUserAvatar(newAvatar, 50)} />) : ''}
                  <input type="text" style={{ width: newAvatar ? '63%' : '' }} onChange={handleChangePersonName} value={newPersonName} maxLength={20} placeholder="Batman, Frodo, Tom Hanks..."/>
                </div>

                <div>
                  <h3>¿Qué quieres tomar?</h3>
                  <select onChange={handleChangeCoffeeType} value={newCoffeeType}>
                    {coffeTypes.map((coffeeType: any) => (
                      <option key={coffeeType} value={coffeeType}>
                        {renderCoffeType(coffeeType).emoji} {renderCoffeType(coffeeType).name}
                      </option>
                    ))}
                  </select>
                </div>

                <a onClick={handleSubmit} className="btn-dame-cafe"><FontAwesomeIcon icon={faCoffee} /> Dame Café!</a>
              </form>
            </div>
          </div>
        </div>
        {error ? (<h4 style={{ color: '#e9576f', fontSize: 20, background: '#1a1d28' }} className='warning'><span style={{ fontWeight: 100 }} >🐵 </span> La API ha petado! 😕</h4>) : ''}
        {success ? (<h4 style={{ color: '#238636', fontSize: 20, background: '#1a1d28' }} className='success'><span style={{ fontWeight: 100 }} >☕ </span> Perfecto, Marchando!</h4>) : ''}
        {faltanDatos ? (<h4 style={{ color: '#e9576f', fontSize: 20, background: '#1a1d28' }} className='warning'><span style={{ fontWeight: 100 }} >🐵 </span> Completa todos los datos!</h4>) : ''}
        {faltaCoffeeSpace ? (<h4 style={{ color: '#e9576f', fontSize: 20, background: '#1a1d28' }} className='warning'><span style={{ fontWeight: 100 }} >🚀 </span> Tienes que viajar a tu CoffeeSpace!</h4>) : ''}

        <div className="box-content">
          {loading ? (<h4 style={{ color: 'grey', fontSize: 20 }}><FontAwesomeIcon icon={faCircleNotch} size="2x" spin style={{ width: '100%', marginBottom: 15 }}/><FontAwesomeIcon icon={faCoffee} /> Estamos cargando Cafés...</h4>) : ''}
          {coffees.length > 0
            ? coffees.map((coffee: any) => (
            <>
              <li key={coffee.id} id={coffee.id} className="coffee-item">

                <div key={coffee.id + '_1'} className="coffee-group">
                  <img src={renderUserAvatar(coffee.personName, 70)} />
                </div>

                <div key={coffee.id + '_2'} className="coffee-group">
                  <h3>{coffee.personName}</h3>
                  <h2 className="coffe-type">{renderCoffeType(coffee.coffeeType).name}</h2>
                </div>

                <div key={coffee.id + '_3'} onClick={() => handleDelete(coffee.id)} className="coffee-group">
                  <h2 className="btn-elimina-cafe"><FontAwesomeIcon icon={faTrash} /></h2>
                </div>

              </li>
            </>
            ))
            : (!loading && coffeeSpaceState ? <span>Hoy nadie ha pedido café todavía... ☕<br/> <strong>Espabila! Qué esperas?</strong></span> : '')
            }
        </div>
        {coffees.length > 0
          ? (
        <div className="box-content">
            <p>Copia el texto o comparte el resumen!</p>
            <textarea className="coffee-text-area" onFocus={handleFocus} value={textAreaText} readOnly />
              <div>
               <WhatsappShareButton style={{ paddingRight: 15 }} url={'Coffee App'} title={textAreaText} openShareDialogOnClick={true}>
                    <WhatsappIcon type="button" size={40} round={true} />
                </WhatsappShareButton>
                <TelegramShareButton style={{ paddingRight: 15 }} url={'.'} title={textAreaText}>
                    <TelegramIcon type="button" size={40} round={true} />
                </TelegramShareButton>
                <LineShareButton url={'.'} title={textAreaText}>
                    <LineIcon type="button" size={40} round={true} />
                </LineShareButton>
              </div>
        </div>)
          : ''}
        <div className="footer">
          <a href="https://github.com/Pixelao" target="_blank" className="devby" rel="nofollow noopener noreferrer"><span style={{ fontWeight: 500 }}>v0.4.2 </span> Developed by: <strong>AdrianMr</strong>#Pixelao</a>
        </div>
      </div>
    </div>
  )
}

export default App
