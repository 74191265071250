import axios from 'axios'

export const createCoffee = ({ personName, coffeeType, coffeeSpace }: any) => {
  coffeeSpace = coffeeSpace.toLowerCase()
  return axios
    .post('https://coffe-app-backend.onrender.com/api/coffees', { personName, coffeeType, coffeeSpace })
    .then(response => {
      const { data } = response
      return data
    })
}
