import axios from 'axios'

export const getAllCoffees = ({ coffeeSpace }: any) => {
  coffeeSpace = coffeeSpace.toLowerCase()
  return axios
    .get('https://coffe-app-backend.onrender.com/api/coffees/' + coffeeSpace)
    .then((response) => {
      const { data } = response
      return (data)
    })
}
