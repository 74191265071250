import axios from 'axios'

export const deleteCoffee = ({ id }: any) => {
  return axios
    .delete('https://coffe-app-backend.onrender.com/api/coffees/' + id)
    .then(response => {
      const { data } = response
      return data
    })
}
